import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/Main.css'; 
import { Link } from 'react-router-dom';
import DavidRozman from '../assets/Portfolio/davidrozman.png';

const DavidRozmanCase = () => {
  return (
    <>
      <Helmet>
        <title>David Rozman Salon | Conversion Lab</title>
        <meta name="description" content="Discover how ConversionLab transformed David Rozman's luxury salon website with modern design and seamless booking integration." />
      </Helmet>
      <div className='portfolio-page'>
        <div className='page-header p-5'>
          <div className='row pt-1'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
              <div className='portfolio-box-img'>
                <img src={DavidRozman} width="100%" alt="David Rozman Salon Website Showcase"></img>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
              <div className='portfolio-box-text'>
                <span className='span-tag2'>CASE STUDY</span>
                <h3>Luxury Website Redesign for David Rozman Salon</h3>
                <p>Complete website transformation featuring modern design, integrated booking system, and optimised user experience for this premium Manchester hair salon.</p>

                <div className='portfolio-box-stats'>
                  <div className='portfolio-stat'>
                    <h2>85%</h2>
                    <p>Increase in site engagement</p>
                  </div>
                  <div className='portfolio-stat'>
                    <h2>40%</h2>
                    <p>More online bookings</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3'>
              <div className='portfolio-box-text'>
                <div className='row'>
                  <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                    <div className='text-center portfolio-page-desc'>
                      <p>David Rozman is a luxury hair salon in Manchester renowned for its bespoke styling and hair care for both men and women. Combining innovation, expertise, and a personal touch, the salon is dedicated to delivering exceptional experiences tailored to each client. Despite its stellar reputation, David recognised the need to modernise his online presence to reflect the salon's premium services and streamline client engagement.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container pt-2 service-page-bg2 pb-4'>
        <div className="service-page-process">
          <div className='row'>
            <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
              
              <strong>Challenge</strong>
              <li>The salon's previous website was outdated and did not reflect the luxury and professionalism of the brand. It suffered from numerous issues, including:</li>
              <li>Aesthetic Limitations: The design lacked modern flair and used outdated images that didn't align with the salon's high standards.</li>
              <li>Functional Errors: Spelling mistakes, broken buttons, and slow loading times created a poor user experience.</li>
              <li>Overwhelming Content: Excessive text made the website feel cluttered and deterred potential clients from engaging.</li>
              
              <strong>Solution</strong>
              <li>ConversionLab rebuilt the website from the ground up, focusing on elegance, performance, and usability. The key improvements included:</li>

              <strong>Key Features:</strong>
              <li>Modern and Luxurious Design: The new website features a clean, sleek aesthetic that highlights the salon's professionalism and premium services.</li>
              <li>Enhanced User Experience: Simplified navigation, updated visuals, and concise, engaging content ensure a seamless experience for users.</li>
              <li>Technical Overhaul: Fixed errors, removed clutter, and optimised loading times to improve performance and user satisfaction.</li>
              <li>Custom Booking System: Integrated a user-friendly booking system to streamline appointment scheduling.</li>
              <li>Mobile Optimisation: Designed the site to look and perform flawlessly on all devices.</li>
              <li>SEO Strategy: Implemented ongoing SEO to enhance the website's visibility in Manchester.</li>

              <strong>Results:</strong>
              <li>The new website delivered a dramatic transformation:</li>
              <li>Increased Online Engagement: A sleek design and clear user journey have kept visitors on the site longer.</li>
              <li>Enhanced Brand Perception: The modernised site reflects the salon's luxury ethos.</li>
              <li>Improved Operational Efficiency: The booking system reduced administrative tasks significantly.</li>
              <li>Positive Feedback: Clients have praised the site's ease of use and professional impression.</li>

              <strong>Future Projections:</strong>
              <li>ConversionLab is continuing its partnership with David Rozman through ongoing SEO optimisation, ensuring the salon maintains its competitive edge in the luxury hair care market in Manchester.</li>

              <strong>Testimonial:</strong>
              <li>"The transformation ConversionLab brought to our website is nothing short of incredible. The new site feels like an extension of our salon—luxurious, modern, and easy to navigate. The booking system has been a game-changer, and I've already noticed an increase in new clients reaching out. I'm excited to see how SEO will take us even further!"</li>
              <p>– David Rozman, Founder of David Rozman Salon</p>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DavidRozmanCase;