/* global dataLayer */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Helmet for SEO
import AnnouncementBar from './components/AnnouncementBar';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Brands from './components/Brands';
import MouseFollower from './components/MouseFollower';
import Portfolio from './components/Portfolio';
import Reviews from './components/Reviews';
import Pricing from './components/Pricing';
import Why from './components/Why';
import Contact from './components/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Seo from './pages/Seo';
import SEO from './pages/Seo';
import PrivacyPolicy from './pages/Privacy-policy';
import CookiePolicy from './pages/Cookie-policy';
import TermsAndConditions from './pages/Terms-conditions';
import WebDesign from './pages/Web-design';
import DigitalMarketing from './pages/Digital-marketing';
import AdCreation from './pages/Ad-creation';
import SocialMedia from './pages/Social-media';
import BrandManagement from './pages/Brand-management';
import BookMeeting from './pages/Book-meeting';
import NotFound from './pages/404';
import About from './pages/About'; // Import About page
import Projects from './pages/Projects';
import ContactPage from './pages/Contact';
import ServicesPage from './pages/Services';
import ReviewsPage from './pages/Reviews';
import MassEmail from './pages/MassEmail';
import NoSnorePage from './pages/no-snore';
import BoomSevenPage from './pages/boomseven';
import GluteFlexPage from './pages/gluteflex';
import DavidRozmanPage from './pages/davidrozman';
import DalleDesignsPage from './pages/dalledesigns';
import Confirmation from './components/Confirmation';

import './css/Main.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  useEffect(() => {
    // Google Analytics
    const googleAnalyticsScript = document.createElement('script');
    googleAnalyticsScript.src = "https://www.googletagmanager.com/gtag/js?id=G-YJV8T5SCPS";
    googleAnalyticsScript.async = true;
    document.head.appendChild(googleAnalyticsScript);

    // Initialize dataLayer for Google Analytics
    window.dataLayer = window.dataLayer || [];
    
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-YJV8T5SCPS');

    // Klaviyo script
    const klaviyoScript = document.createElement('script');
    klaviyoScript.type = 'text/javascript';
    klaviyoScript.src = '//static.klaviyo.com/onsite/js/klaviyo.js?company_id=RyYTxh';
    document.head.appendChild(klaviyoScript);

    // Function to initialize or disable AOS based on screen size
    const initializeAOS = () => {
      if (window.innerWidth > 992) {
        AOS.init({ disable: false }); // Enable AOS on desktop
      } else {
        AOS.init({ disable: true });  // Disable AOS on mobile
      }
    };

    // Call initializeAOS on load
    initializeAOS();

    // Add event listener for resizing
    window.addEventListener('resize', initializeAOS);
    
    // Cleanup the event listeners and scripts on unmount
    return () => {
      window.removeEventListener('resize', initializeAOS);
      document.head.removeChild(googleAnalyticsScript);
      document.head.removeChild(klaviyoScript);
    };
  }, []); // Empty dependency array to ensure it runs only once

  return (
    <HelmetProvider>
      <Router>
      <ScrollToTop />
        {/* Wrapper div around the whole site */}
        <div className="main-wrapper" style={{ overflowX: 'hidden' }}>
          <MouseFollower />
          <AnnouncementBar />
          <Navbar />

          {/* Define Routes */}
          <Routes>
            {/* Home Page */}
            <Route path="/" element={
              <>
                <Helmet>
                  <title>Conversion Lab | Web Development & Brand Management</title>
                  <meta name="description" content="Conversion Lab helps you craft modern, high-converting websites, manage ad campaigns, and optimise your brand's online presence." />
                  <meta name="keywords" content="web development, SEO, digital marketing, brand management, social media, Google Ads, Facebook Ads" />
                  <meta property="og:title" content="Conversion Lab - Your Web Development Partner" />
                  <meta property="og:description" content="We create high-converting websites and manage brand strategies for growing businesses." />
                  <meta property="og:image" content="/path-to-social-share-image.png" />
                  <meta property="og:url" content="https://www.conversionlab.io" />
                  <meta property="og:type" content="website" />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:title" content="Conversion Lab - Web Development & Brand Management" />
                  <meta name="twitter:description" content="Helping businesses grow with high-converting websites and strategic brand management." />
                  <meta name="twitter:image" content="/path-to-twitter-image.png" />
                  <meta name="twitter:site" content="@conversionlab" />
                </Helmet>

                {/* Components for the Home page */}
                <Hero />
                <Services />
                <Brands />
                <Portfolio />
                <Reviews />
                <Pricing />
                <Why />
                <Contact />
              </>
            } />

            {/* Additional Pages */}
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/services" element={<ServicesPage/>} />
            <Route path="/reviews" element={<ReviewsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/massemail" element={<MassEmail />} />

            {/* Service-Specific Pages */}
            <Route path="/web-design" element={<WebDesign />} />
            <Route path="/seo" element={<Seo />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/brand-management" element={<BrandManagement />} />
            <Route path="/social-media" element={<SocialMedia />} />
            <Route path="/ad-creation" element={<AdCreation />} />

            {/* Policy Pages */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

            {/* Meeting Page */}
            <Route path="/book-a-meeting" element={<BookMeeting />} />
            <Route path="/confirmation" element={<Confirmation />} />

            {/* Portfolio */}
            <Route path="/no-snore" element={<NoSnorePage />} />
            <Route path="/glute-flex" element={<GluteFlexPage />} />
            <Route path="/dalle-designs" element={<DalleDesignsPage />} />
            <Route path="/boomseven" element={<BoomSevenPage />} />
            <Route path="/david-rozman" element={<DavidRozmanPage />} />

            {/* 404 Page */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          
          {/* Footer Component */}
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
