import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/Main.css'; 
import logo from '../assets/logo.png'; 
import logowhite from '../assets/logowhite.svg'; 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import Calendar from '../assets/calendar.svg';
import { Accordion } from 'react-bootstrap';
import Check from '../assets/check.svg';
import FourStepProcess from '../components/FourStageProcess.js';

const DigitalMarketing = () => {
  return (
    <>
      <Helmet>
        <title>Digital Marketing | Conversion Lab</title>
        <meta name="description" content="Discover our digital marketing services and how we can help create a social media ads for your business." />
      </Helmet>
      <div className='page-header '>
        <div className=' text-center' style={{ padding: '100px 0px 150px 0px' }}       >
        <h1>Digital Marketing</h1>
          <div className='row m-auto'>
                    <div className='col-lg-5 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                        <div className=''>
                        <p>We craft data-driven digital marketing strategies that amplify your brand’s presence, attract the right audience, and drive measurable results, helping your business grow and thrive online.</p>
                    </div>
                </div>
              </div>


            <Link to="/book-a-meeting" className="btn"> {/* Button link to Contact */}
            Schedule a call <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px " alt='arrow-icon'></img>
            </Link>
        </div>
      </div>
      <div className="service-page-main pt-5 pb-5">
        <div className='container'>
              <div className='row'>
                  <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                  <h1><span className='span-tag2'>BENEFITS</span>Why you need Digital Marketing</h1>
                  </div>
              </div>
          </div>

          <div className='container service-page-text' data-aos="fade-up" data-aos-delay="00">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 about-text' data-aos="fade-up" data-aos-delay="00">
            <p>
            <span>Digital Marketing</span> encompasses a range of strategies aimed at promoting your brand across various online platforms, including social media, search engines, and more. Effective digital marketing not only boosts visibility but also fosters engagement and connection with your target audience. 
            <span> By leveraging platforms like Facebook, Google, Snapchat, Pinterest, and Instagram, a comprehensive digital marketing strategy helps convey your brand’s message, attract potential customers, and ultimately drive conversions.</span> Here are several reasons why a robust digital marketing approach is essential for your business:
          </p>


            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 about-text' data-aos="fade-up">
  <Accordion defaultActiveKey="10">
    {/* 01. Increased Online Visibility */}
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>01.</span> Increased Online Visibility
      </Accordion.Header>
      <Accordion.Body>
        A comprehensive digital marketing strategy enhances your brand's online visibility, ensuring you reach potential customers where they spend their time—online.
      </Accordion.Body>
    </Accordion.Item>

    {/* 02. Targeted Advertising */}
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>02.</span> Targeted Advertising
      </Accordion.Header>
      <Accordion.Body>
        Digital marketing enables precise targeting, ensuring your ads reach the right audience based on demographics, interests, and online behavior, maximizing return on investment.
      </Accordion.Body>
    </Accordion.Item>

    {/* 03. Enhanced Customer Engagement */}
    <Accordion.Item eventKey="2">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>03.</span> Enhanced Customer Engagement
      </Accordion.Header>
      <Accordion.Body>
        Through social media and content marketing, you can engage directly with your audience, fostering relationships and encouraging brand loyalty.
      </Accordion.Body>
    </Accordion.Item>

    {/* 04. Data-Driven Insights */}
    <Accordion.Item eventKey="3">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>04.</span> Data-Driven Insights
      </Accordion.Header>
      <Accordion.Body>
        Digital marketing provides measurable results and analytics, allowing you to track performance, adjust strategies, and make informed decisions to optimize campaigns.
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
</div>

<div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 about-text' data-aos="fade-up">
  <Accordion defaultActiveKey="0">
    {/* 05. Cost-Effective Solutions */}
    <Accordion.Item eventKey="4">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>05.</span> Cost-Effective Solutions
      </Accordion.Header>
      <Accordion.Body>
        Compared to traditional marketing, digital marketing is often more cost-effective, allowing businesses to reach larger audiences without breaking the bank.
      </Accordion.Body>
    </Accordion.Item>

    {/* 06. Improved Conversion Rates */}
    <Accordion.Item eventKey="5">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>06.</span> Improved Conversion Rates
      </Accordion.Header>
      <Accordion.Body>
        With targeted campaigns and optimized landing pages, digital marketing strategies can significantly increase conversion rates, turning visitors into customers.
      </Accordion.Body>
    </Accordion.Item>

    {/* 07. SEO and Content Marketing Synergy */}
    <Accordion.Item eventKey="6">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>07.</span> SEO and Content Marketing Synergy
      </Accordion.Header>
      <Accordion.Body>
        Combining SEO with content marketing ensures your content is discoverable and relevant, driving organic traffic to your site and establishing your authority in the industry.
      </Accordion.Body>
    </Accordion.Item>

    {/* 08. Scalability for Business Growth */}
    <Accordion.Item eventKey="7">
      <Accordion.Header>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>08.</span> Scalability for Business Growth
      </Accordion.Header>
      <Accordion.Body>
        Digital marketing strategies are scalable, allowing you to expand your efforts as your business grows, adapting to new opportunities and changing market conditions.
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
</div>

          </div>
        </div>
        </div>

        <div className='container pt-5 service-page-bg2'>
              <div className='row'>
                  <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                  <h1><span className='span-tag2'>DETAILS</span>What We Do</h1>
                  </div>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 about-text p-1' data-aos="fade-up">
                  <p>Digital Marketing is a comprehensive approach aimed at enhancing your online presence and driving targeted traffic to your brand. It encompasses various strategies such as search engine marketing, social media advertising, and content marketing to create <span>engaging campaigns that resonate with your audience</span>. A successful digital marketing strategy not only increases visibility but also ensures that potential customers can connect with your brand and convert into loyal patrons. Here's an overview of the essential aspects of digital marketing:</p>
                </div>

                <div className="service-page-process" data-aos="fade-up">
                  <strong>Market Research</strong>
                  <li>Conducting thorough research to understand the target audience, market trends, and competitors, ensuring that marketing efforts are strategic and effective.</li>

                  <strong>Content Strategy</strong>
                  <li>Developing a comprehensive content strategy that outlines the type of content to be created, its distribution channels, and how it aligns with audience interests.</li>

                  <strong>SEO Optimization</strong>
                  <li>Implementing on-page and off-page SEO strategies to improve search engine rankings and increase organic traffic to the website.</li>

                  <strong>Social Media Marketing</strong>
                  <li>Creating and managing engaging social media campaigns to enhance brand awareness, connect with customers, and foster community engagement.</li>

                  <strong>PPC Advertising</strong>
                  <li>Utilizing pay-per-click advertising to drive targeted traffic through search engines and social media platforms, optimizing ads for maximum ROI.</li>

                  <strong>Email Marketing</strong>
                  <li>Developing personalized email marketing campaigns to nurture leads, inform customers about new products, and promote special offers.</li>

                  <strong>Analytics and Reporting</strong>
                  <li>Monitoring campaign performance using analytics tools, providing insights and adjustments to improve strategies and maximize results.</li>

                  <strong>Continuous Improvement</strong>
                  <li>Adapting and optimizing digital marketing strategies based on performance data and market changes to ensure ongoing success and growth.</li>
                </div>

              
          </div>

          <div className='container pb-5 mt-5 All-Services-Benefits pt-4'>

            <div className='row pb-3'>
                  <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                  <h1><span className='span-tag2'>WHY CHOOSE US</span>Why we are your best choice</h1>
                  </div>
              </div>

          <div className='row m-auto justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="0">
              <div className='our-benefits-box'>
                    <div className='our-benefits-box-head'>
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9996 10.9999L3.49964 20.4999M14.0181 3.53838C15.2361 4.34658 16.4068 5.29941 17.5008 6.3934C18.6042 7.49683 19.564 8.67831 20.3767 9.90766M9.2546 7.89605L6.37973 6.93776C6.04865 6.8274 5.68398 6.89763 5.41756 7.12306L2.56041 9.54065C1.97548 10.0356 2.14166 10.9775 2.86064 11.2424L5.56784 12.2398M11.6807 18.3524L12.6781 21.0596C12.943 21.7786 13.8849 21.9448 14.3798 21.3599L16.7974 18.5027C17.0228 18.2363 17.0931 17.8716 16.9827 17.5405L16.0244 14.6657M19.3482 2.27063L14.4418 3.08838C13.9119 3.17668 13.426 3.43709 13.0591 3.82932L6.446 10.8985C4.73185 12.7308 4.77953 15.5924 6.55378 17.3667C8.32803 19.1409 11.1896 19.1886 13.022 17.4744L20.0911 10.8614C20.4834 10.4944 20.7438 10.0085 20.8321 9.47869L21.6498 4.57222C21.8754 3.21858 20.7019 2.04503 19.3482 2.27063Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className='our-benefits-box-text'>
                        <h3>Proven Track Record</h3>
                        <p>We’ve earned a reputation for driving measurable success through proven strategies that elevate brands.</p>
                    </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="200">
              <div className='our-benefits-box'>
                    <div className='our-benefits-box-head'>
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9995 12.0001H12.0095M15.535 15.5357C10.8488 20.222 5.46685 22.438 3.51423 20.4854C1.56161 18.5328 3.77769 13.1509 8.46398 8.46461C13.1503 3.77832 18.5322 1.56224 20.4848 3.51486C22.4374 5.46748 20.2213 10.8494 15.535 15.5357ZM15.535 8.46443C20.2213 13.1507 22.4374 18.5326 20.4848 20.4852C18.5321 22.4379 13.1502 20.2218 8.46394 15.5355C3.77765 10.8492 1.56157 5.4673 3.51419 3.51468C5.46681 1.56206 10.8487 3.77814 15.535 8.46443ZM12.4995 12.0001C12.4995 12.2763 12.2757 12.5001 11.9995 12.5001C11.7234 12.5001 11.4995 12.2763 11.4995 12.0001C11.4995 11.724 11.7234 11.5001 11.9995 11.5001C12.2757 11.5001 12.4995 11.724 12.4995 12.0001Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className='our-benefits-box-text'>
                        <h3>Tailored Solutions</h3>
                        <p>We design strategies that align perfectly with your business goals, audience, and industry specifics..</p>
                    </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="400">
              <div className='our-benefits-box'>
                    <div className='our-benefits-box-head'>
                        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className='our-benefits-box-text'>
                        <h3>Client-Centric Focus</h3>
                        <p>Your success is our main focus. We immerse ourselves in understanding your business to ensure you achieve your objectives.</p>
                    </div>
              </div>
            </div>

            {/* Transparent Communication */}
            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="600">
              <div className='our-benefits-box'>
                <div className='our-benefits-box-head'>
                  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 15V8C21 6.11438 21 5.17157 20.4142 4.58579C19.8284 4 18.8856 4 17 4H7C5.11438 4 4.17157 4 3.58579 4.58579C3 5.17157 3 6.11438 3 8V15C3 16.8856 3 17.8284 3.58579 18.4142C4.17157 19 5.11438 19 7 19H10L12 21L14 19H17C18.8856 19 19.8284 19 20.4142 18.4142C21 17.8284 21 16.8856 21 15Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div className='our-benefits-box-text'>
                  <h3>Transparent Communication</h3>
                  <p>We maintain open and clear communication, ensuring you’re informed and confident in every decision.</p>
                </div>
              </div>
            </div>

            {/* Dedicated Support */}
            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="800">
              <div className='our-benefits-box'>
                <div className='our-benefits-box-head'>
                  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4V5H20C20.5523 5 21 5.44772 21 6V20C21 20.5523 20.5523 21 20 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div className='our-benefits-box-text'>
                  <h3>Dedicated Support</h3>
                  <p>Our team is always available, offering proactive support to keep your business moving forward smoothly.</p>
                </div>
              </div>
            </div>

            {/* Expertise Across Industries */}
            <div className='col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12 mb-2' data-aos="fade-up" data-aos-delay="1000">
              <div className='our-benefits-box'>
                <div className='our-benefits-box-head'>
                  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C7.58 2 4 5.58 4 10C4 15.25 11.27 21.54 11.65 21.84C11.85 21.99 12.15 21.99 12.35 21.84C12.73 21.54 20 15.25 20 10C20 5.58 16.42 2 12 2ZM12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div className='our-benefits-box-text'>
                  <h3>Expertise Across Industries</h3>
                  <p>From startups to established brands, our experience spans multiple industries, bringing new perspectives to every project.</p>
                </div>
              </div>
            </div>

        </div>
        </div>

        <FourStepProcess />

        <div className="container service-page-pricing">
        <div className='row pb-3'>
                  <div  data-aos="" className='col-12 text-lg-start text-md-start text-center mb-3'>
                  <h1><span className='span-tag2'>PRICING</span>Profitable Pricing Plans</h1>
                  </div>
              </div>

              <div className={`tab-pane fade show`} id="pills-googleads" role="tabpanel" aria-labelledby="pills-googleads-tab">
            <div className='row justify-content-center'>

                <p className='text-center text-sm-center text-xs-center text-md-start text-lg-start'>Our pricing ranges from just 3% to 10% of your ad spend, compared to the typical 15% to 20%—because delivering value shouldn’t come with inflated costs.</p>
  <div className='col-lg-8 col-md-10 col-sm-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                            <div className='pricing-box pricing-box-enterprise'>
                                <h3>Enterprise Google Ads</h3>
                                <p>Large-scale ad spend management, focused on scaling campaigns, high-frequency optimisation, and ROI analysis.</p>
                                <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                                <Link to='/book-a-meeting' className="btn">
                                    SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px" alt='calendar-icon' />
                                </Link>
                                <ul>
                                    <li><img src={Check} width="30px" alt="check" /> Full-Scale Campaign Management</li>
                                    <li><img src={Check} width="30px" alt="check" /> High-Frequency Optimisation</li>
                                    <li><img src={Check} width="30px" alt="check" /> Detailed ROI Analysis</li>
                                    <li><img src={Check} width="30px" alt="check" /> Automation & Scaling Strategies</li>
                                    <li><img src={Check} width="30px" alt="check" /> Weekly Ad Account Updates & Detailed Reporting</li>
                                    <li><img src={Check} width="30px" alt="check" /> Customised Enterprise-Level Solutions</li>
                                </ul>
                            </div>
                        </div>

                </div>

            </div>
        </div>
    </>
  );
};

export default DigitalMarketing;
