import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../css/Pricing.css' 
import '../css/Main.css' 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Convertlab from '../assets/Portfolio/convertlab-main.png';
import Dalle from '../assets/Portfolio/dalle-designs-main.png';
import Gluteflex from '../assets/Portfolio/gluteflex-main.png';
import Nosnore from '../assets/Portfolio/nosnore-main.png';
import star from '../assets/star-solid.svg';
import Oliver from '../assets/oliver.png';
import Check from '../assets/check.svg';
import Calendar from '../assets/calendar.svg';


const Pricing = () => {
  const [selectedOption, setSelectedOption] = useState("WEB DESIGN");
  const pillsContainerRef = useRef(null);
  const slidingBackgroundRef = useRef(null);

  const isMobile = () => window.innerWidth <= 768;

  useEffect(() => {
    if (!isMobile()) {
      AOS.init({
        once: true,
      });
    }

    const pillsContainer = pillsContainerRef.current;
    const slidingBackground = slidingBackgroundRef.current;
    const pills = pillsContainer.querySelectorAll('.nav-link');

    const updateBackgroundPosition = (activePill) => {
      const pillRect = activePill.getBoundingClientRect();
      const containerRect = pillsContainer.getBoundingClientRect();
      const offsetX = pillRect.left - containerRect.left;

      slidingBackground.style.width = `${pillRect.width}px`;
      slidingBackground.style.transform = `translateX(${offsetX}px)`;
    };

    const activePill = pillsContainer.querySelector('.nav-link.active');
    if (activePill) {
      updateBackgroundPosition(activePill);
    }

    pills.forEach(pill => {
      pill.addEventListener('click', (event) => {
        pills.forEach(p => p.classList.remove('active'));
        event.target.classList.add('active');
        updateBackgroundPosition(event.target);
        setSelectedOption(event.target.textContent.trim().toUpperCase());
      });
    });

    return () => {
      pills.forEach(pill => {
        pill.removeEventListener('click', updateBackgroundPosition);
      });
    };
  }, []);

return (
  <div className='Pricing pt-5'>
      <div className='container'>
          <div className='row'>
              <div data-aos="" className='col-12 text-lg-start text-md-start text-center mb'>
                  <h1><span className='span-tag2'>PRICING</span>Enterprise Solutions</h1>
                  <p>Tailored Solutions for Your Business Needs</p>

                  <ul className='nav nav-pills mb-4 justify-content-center desktop-pills' ref={pillsContainerRef} id='pills-tab' role='tablist'>
                      <div className='sliding-background' ref={slidingBackgroundRef}></div>
                      <li className='nav-item' role='presentation'>
                          <button className='nav-link active' id='pills-webdesign-tab' data-bs-toggle='pill' data-bs-target='#pills-webdesign' type='button' role='tab' aria-controls='pills-webdesign' aria-selected='false'>
                              WEB DESIGN
                          </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                          <button className='nav-link' id='pills-seo-tab' data-bs-toggle='pill' data-bs-target='#pills-seo' type='button' role='tab' aria-controls='pills-seo' aria-selected='true'>
                              SEO
                          </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                          <button className='nav-link' id='pills-googleads-tab' data-bs-toggle='pill' data-bs-target='#pills-googleads' type='button' role='tab' aria-controls='pills-googleads' aria-selected='false'>
                              GOOGLE ADS
                          </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                          <button className='nav-link' id='pills-facebookads-tab' data-bs-toggle='pill' data-bs-target='#pills-facebookads' type='button' role='tab' aria-controls='pills-facebookads' aria-selected='false'>
                              FACEBOOK ADS
                          </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                          <button className='nav-link' id='pills-socialmedia-tab' data-bs-toggle='pill' data-bs-target='#pills-socialmedia' type='button' role='tab' aria-controls='pills-socialmedia' aria-selected='false'>
                              SOCIAL MEDIA
                          </button>
                      </li>
                  </ul>

                  <div className="mobile-dropdown mb-4">
                      <select
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                          className="form-select"
                          aria-labelledby={`pills-${selectedOption.toLowerCase()}-tab`}
                      >
                          <option value="WEB DESIGN">WEB DESIGN</option>
                          <option value="SEO">SEO</option>
                          <option value="GOOGLE ADS">GOOGLE ADS</option>
                          <option value="FACEBOOK ADS">FACEBOOK ADS</option>
                          <option value="SOCIAL MEDIA">SOCIAL MEDIA</option>
                      </select>
                  </div>
              </div>
          </div>
      </div>

      <div className='container review-row'>
          <div className="tab-content" id="pills-tabContent">
              {/* SEO Tab */}
              <div className={`tab-pane fade show ${selectedOption === "SEO" ? "active" : ""}`} id="pills-seo" role="tabpanel" aria-labelledby="pills-seo-tab">
                  <div className='row justify-content-center'>
                      <div className='col-lg-8 col-md-10 col-sm-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                          <div className='pricing-box pricing-box-enterprise'>
                              <h3>Enterprise SEO</h3>
                              <p>A comprehensive solution for large businesses requiring advanced SEO strategies and optimisation.</p>
                              <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                              <Link to='/book-a-meeting' className="btn">
                                  SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px" alt='calendar-icon' />
                              </Link>
                              <ul>
                                  <li><img src={Check} width="30px" alt="check" /> Custom SEO strategy for large sites</li>
                                  <li><img src={Check} width="30px" alt="check" /> Multi-level content optimisation (blogs, product pages, services)</li>
                                  <li><img src={Check} width="30px" alt="check" /> Advanced technical SEO (schema markup, mobile-first indexing)</li>
                                  <li><img src={Check} width="30px" alt="check" /> Monthly in-depth SEO analysis and reporting</li>
                                  <li><img src={Check} width="30px" alt="check" /> Ongoing SEO support and consultation</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>

              {/* Web Design Tab */}
              <div className={`tab-pane fade show ${selectedOption === "WEB DESIGN" ? "active" : ""}`} id="pills-webdesign" role="tabpanel" aria-labelledby="pills-webdesign-tab">
                  <div className='row justify-content-center'>
                      <div className='col-lg-8 col-md-10 col-sm-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                          <div className='pricing-box pricing-box-enterprise'>
                              <h3>Enterprise Web Design</h3>
                              <p>A comprehensive solution for large businesses requiring advanced features and extensive content management.</p>
                              <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                              <Link to='/book-a-meeting' className="btn">
                                  SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px" alt='calendar-icon' />
                              </Link>
                              <ul>
                                  <li><img src={Check} width="30px" alt="check" /> Fully customised website with advanced features</li>
                                  <li><img src={Check} width="30px" alt="check" /> Web hosting, domain, and emails included</li>
                                  <li><img src={Check} width="30px" alt="check" /> E-commerce integration (if applicable)</li>
                                  <li><img src={Check} width="30px" alt="check" /> Continuous technical support</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>

              {/* Google Ads Tab */}
              <div className={`tab-pane fade show ${selectedOption === "GOOGLE ADS" ? "active" : ""}`} id="pills-googleads" role="tabpanel" aria-labelledby="pills-googleads-tab">
                  <div className='row justify-content-center'>
                      <p className='text-center text-sm-center text-xs-center text-md-start text-lg-start'>Our pricing ranges from just 3% to 10% of your ad spend, compared to the typical 15% to 20%—because delivering value shouldn't come with inflated costs.</p>
                      <div className='col-lg-8 col-md-10 col-sm-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                          <div className='pricing-box pricing-box-enterprise'>
                              <h3>Enterprise Google Ads</h3>
                              <p>Large-scale ad spend management, focused on scaling campaigns, high-frequency optimisation, and ROI analysis.</p>
                              <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                              <Link to='/book-a-meeting' className="btn">
                                  SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px" alt='calendar-icon' />
                              </Link>
                              <ul>
                                  <li><img src={Check} width="30px" alt="check" /> Full-Scale Campaign Management</li>
                                  <li><img src={Check} width="30px" alt="check" /> High-Frequency Optimisation</li>
                                  <li><img src={Check} width="30px" alt="check" /> Detailed ROI Analysis</li>
                                  <li><img src={Check} width="30px" alt="check" /> Automation & Scaling Strategies</li>
                                  <li><img src={Check} width="30px" alt="check" /> Weekly Ad Account Updates & Detailed Reporting</li>
                                  <li><img src={Check} width="30px" alt="check" /> Customised Enterprise-Level Solutions</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>

              {/* Facebook Ads Tab */}
              <div className={`tab-pane fade show ${selectedOption === "FACEBOOK ADS" ? "active" : ""}`} id="pills-facebookads" role="tabpanel" aria-labelledby="pills-facebookads-tab">
                  <div className='row justify-content-center'>
                      <p className='text-center text-sm-center text-xs-center text-md-start text-lg-start'>Our pricing ranges from just 3% to 10% of your ad spend, compared to the typical 15% to 20%—because delivering value shouldn't come with inflated costs.</p>
                      <div className='col-lg-8 col-md-10 col-sm-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                          <div className='pricing-box pricing-box-enterprise'>
                              <h3>Enterprise Facebook Ads</h3>
                              <p>Large-scale ad spend management, focused on scaling campaigns, high-frequency optimisation, and ROI analysis.</p>
                              <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                              <Link to='/book-a-meeting' className="btn">
                                  SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px" alt='calendar-icon' />
                              </Link>
                              <ul>
                                  <li><img src={Check} width="30px" alt="check" /> Full-Scale Campaign Management</li>
                                  <li><img src={Check} width="30px" alt="check" /> High-Frequency Optimisation & Scaling</li>
                                  <li><img src={Check} width="30px" alt="check" /> Detailed ROI Analysis & Performance Insights</li>
                                  <li><img src={Check} width="30px" alt="check" /> Advanced Automation & Strategies</li>
                                  <li><img src={Check} width="30px" alt="check" /> Weekly Ad Account Updates & Detailed Reporting</li>
                                  <li><img src={Check} width="30px" alt="check" /> Enterprise-Level Custom Solutions</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>

              {/* Social Media Tab */}
              <div className={`tab-pane fade show ${selectedOption === "SOCIAL MEDIA" ? "active" : ""}`} id="pills-socialmedia" role="tabpanel" aria-labelledby="pills-socialmedia-tab">
                  <div className='row justify-content-center'>
                      <div className='col-lg-8 col-md-10 col-sm-12 mb-4' data-aos="fade-up" data-aos-delay="00">
                          <div className='pricing-box pricing-box-enterprise'>
                              <h3>Enterprise Social Media</h3>
                              <p>Comprehensive social media management for larger brands, including in-depth strategy, content creation, and audience building.</p>
                              <p><span className='pricing-box-price-first'>Lets Talk!</span></p>
                              <Link to='/book-a-meeting' className="btn">
                                  SCHEDULE A CALL <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={Calendar} width="25px" alt='calendar-icon' />
                              </Link>
                              <ul>
                                  <li><img src={Check} width="30px" alt="check" /> Custom Posting Schedule (Up to Twice Daily)</li>
                                  <li><img src={Check} width="30px" alt="check" /> Four+ Social Media Platforms</li>
                                  <li><img src={Check} width="30px" alt="check" /> Advanced Audience Targeting & Interaction</li>
                                  <li><img src={Check} width="30px" alt="check" /> Bespoke Content Creation (Videos, Graphics, etc.)</li>
                                  <li><img src={Check} width="30px" alt="check" /> Real-Time Posting & Community Engagement</li>
                                  <li><img src={Check} width="30px" alt="check" /> Detailed Monthly Reports & Analysis</li>
                                <li><img src={Check} width="30px" alt="check" /> Influencer & Partnership Strategy</li>
                                <li><img src={Check} width="30px" alt="check" /> Priority Support & Strategy Meetings</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='pricing-section-button text-center pb-5 pt-3' data-aos="fade-up" data-aos-delay="00">
            <p className="mb-3">
                Ready to discuss your specific requirements?
                <br />
                <span>Schedule a call with us — we'll create a tailored solution for your business.</span>
            </p>
            <Link
                data-aos="fade-up"
                data-aos-delay="00"
                to='/book-a-meeting'
                className="btn"
            >
                GET YOUR FREE CONSULTATION <img style={{ marginTop: '-4px', marginLeft: '5px' }} src={arrowup} width="20px" alt='arrow-icon' />
            </Link>
        </div>
    </div>
</div>
);
};

export default Pricing;