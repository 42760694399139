import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/Main.css'; 
import logo from '../assets/logo.png'; 
import logowhite from '../assets/logowhite.svg'; 
import arrowup from '../assets/arrow-trend-up-solid.svg';
import { Link, useLocation } from 'react-router-dom';
import Calendar from '../assets/calendar.svg';
import { Accordion } from 'react-bootstrap';
import Check from '../assets/check.svg';
import FourStepProcess from '../components/FourStageProcess.js';
import boomseven from '../assets/Portfolio/boom7mg.png'

const Boom7 = () => {
  return (
    <>
      <Helmet>
        <title>Boom7 Case Study | Conversion Lab</title>
        <meta name="description" content="Discover how ConversionLab helped Boom7 establish a powerful online presence with a modern, dynamic website design." />
      </Helmet>
      <div className='portfolio-page'>
        <div className='page-header p-5'>
          <div className='row pt-1'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
              <div className='portfolio-box-img'>
                <img src={boomseven} width="100%" alt="Boom7 Website Showcase"></img>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3'>
              <div className='portfolio-box-text'>
                <span className='span-tag2'>CASE STUDY</span>
                <h3>Modern Web Design & Development for Boom7</h3>
                <p>A bespoke website design showcasing Boom7's dynamic brand identity, featuring mobile-first design, intuitive content management, and seamless user experience.</p>

                <div className='portfolio-box-stats'>
                  <div className='portfolio-stat'>
                    <h2>100%</h2>
                    <p>Digital presence increase</p>
                  </div>
                  <div className='portfolio-stat'>
                    <h2>50%</h2>
                    <p>Growth in client inquiries</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3'>
              <div className='portfolio-box-text'>
                <div className='row'>
                  <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
                    <div className='text-center portfolio-page-desc'>
                      <p>At Boom7, bold moves and big impact define the brand. Specialising in helping businesses start strong, grow smart, and stand out, Boom7 is known for crafting unforgettable branding, designing attention-commanding visuals, and hosting impactful events. Their mission is clear: connect businesses with the right audience, turning ideas into action and action into success.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container pt-2 service-page-bg2 pb-4'>
        <div className="service-page-process">
          <div className='row'>
            <div className='col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 m-auto'>
              
              <strong>Challenge</strong>
              <li>Despite his success and reputation through word-of-mouth, Steven, the owner of Boom7, recognised the need for a website to expand his reach and secure new clients. With no prior online presence, Steven wanted a modern, dynamic platform that would serve as a digital gateway to showcase Boom7's services, attract new clients, and build lasting connections.</li>
              
              <strong>Solution</strong>
              <li>ConversionLab crafted a bespoke website that built upon Boom7's strong foundation and vision. Key elements of the project included:</li>

              <strong>Key Features:</strong>
              <li>Modernised Branding: Ensured Boom7's bold and innovative ethos was reflected online, aligning with current trends and Steven's unique style.</li>
              <li>Mobile Optimisation: Prioritised a mobile-first design to ensure accessibility and a seamless experience for all users.</li>
              <li>User-Friendly Design: Created an intuitive layout with a clear flow that draws users to explore Boom7's services further.</li>
              <li>Editable Functionality: Empowered Steven with a website that is easy to update, ensuring he remains in control of his content.</li>
              <li>Core Features: Included essential elements like an 'About Us' section, services overview, and a contact form, enabling clients to connect with Boom7 quickly and effectively.</li>

              <strong>Results:</strong>
              <li>The new website has delivered transformative results for Boom7:</li>
              <li>Increased Sales and Leads: The platform has generated solid leads and boosted Steven's ability to connect with potential clients, enabling long-term growth.</li>
              <li>Enhanced Connections: By reaching a broader audience online, Boom7 has built meaningful, lasting relationships, aligning with Steven's goal of fostering valuable connections.</li>
              <li>Positive Feedback: Steven was thrilled with the website's design and functionality, confident that it will serve as a cornerstone for future success.</li>

              <strong>Testimonial:</strong>
              <li>"The website ConversionLab built for Boom7 is exactly what I needed to bring my business into the future. It's modern, user-friendly, and perfectly showcases what we're all about. I'm already seeing the benefits with new clients and connections coming in. I couldn't be happier with the results!"</li>
              <p>– Steven, Founder of Boom7</p>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Boom7;